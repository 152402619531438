import React, { useEffect, useState } from "react";
import { Table, Container, Button } from "react-bootstrap";
import classes from "../WebsiteManagement/websiteManagement.module.css";
// import {GetActiveCoupon,DeleteCoupon} from '../../entities/action/action';
import { useSelector } from "react-redux";
import Page from "../pagination/pagination";
import { DeletePincodeManagement } from "../../entities/action/action";

const PincodeList = (props) => {
  const [dataSet, updateDataSet] = useState([]);
  const data = useSelector((state) => state.adminAccount.apiResponse);
  const [update, setUpdate] = useState(false);
  console.log(data);
  const userData = useSelector((state) => state.adminAccount.apiResponse);
  const [count, updateCount] = useState(0);
  const postPerPage = 20;
  const [currentPage, updateCurrentPage] = useState(1);

  useEffect(() => {
    (async () => {
      const myresponse = await fetch(
        `https://api.zupptravel.com/api/v1/pincode/getPincode`,
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const json = await myresponse.json();
      console.log(json);
      if (json?.success) {
        updateDataSet(json);
      }
    })();
  }, [update]);

  return (
    <Container fluid style={{ padding: "0" }}>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>S.No</th>
            <th>Id</th>
            <th>Pincode</th>
            <th>City</th>
            <th>State</th>
          </tr>
        </thead>
        <tbody>
    
            {dataSet && dataSet.pincodes ? (
  dataSet.pincodes.map((data, key) => (
    <tr key={key}>
      <td>{key + 1}</td>
      <td>{data.id}</td>
      <td>{data.pincode}</td>
      <td>{data.city}</td>
      <td>{data.state}</td>
    </tr>
  ))
) : (
  <tr>
    <td colSpan="6">No data available</td>
  </tr>
)}

        </tbody>
      </Table>
      <div
        style={{ display: "flex", justifyContent: "center", margin: "15px 0" }}
      >
        {/* <Page count={count} change={handleChangePage} page={currentPage} /> */}
      </div>
    </Container>
  );
};
export default PincodeList;
