import * as actionTypes from "../actionType";


export const login = (data) => {
  console.log(data);
  return async (dispatch) => {
    dispatch({
      type: actionTypes.LOGIN_INIT,
    });
    try {
      const myresponse = await fetch("https://api.zupptravel.com/WWlogin", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ emailId: data.email, password: data.password }),
      });
      const myjson = await myresponse.json();
      // console.log("myresponse", myjson);

      dispatch({
        type: actionTypes.LOGIN,
        payload: myjson,
      });
    } catch (err) {
      throw err;
    }
  };
};

export const Logout = (data) => {
  console.log(data);
  return async (dispatch) => {
    dispatch({
      type: actionTypes.LOGOUT_INIT,
    });
    try {
      const myresponse = await fetch("https://api.zupptravel.com/WWlogout", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          emailId: data.user.emailId,
          sessionKey: data.code,
        }),
      });
      const myjson = await myresponse.json();
      console.log("myresponse", myjson);

      dispatch({
        type: actionTypes.LOGOUT,
        payload: myjson,
      });
    } catch (err) {
      throw err;
    }
  };
};

export const Pincode = (mydata) => {

  return async (dispatch) => {
    dispatch({
      type: actionTypes.PINCODE_INIT,
    });
    try {
      const myresponse = await fetch(
        `https://api.zupptravel.com/api/v1/pincode/getPincode/${mydata}`,
        {
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const myjson = await myresponse.json();

      dispatch({
        type: actionTypes.PINCODE,
        payload: myjson?.pincode,
      });
    } catch (err) {
      throw err;
    }
  };
};

export const getPincodeById = async(mydata) => {
    try {
        const myresponse = await fetch(
          `https://api.zupptravel.com/api/v1/pincode/getPincode/${mydata}`,
          {
            method: "get",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const myjson = await myresponse.json();
  
        return {
            city: myjson?.pincode?.city,
            state: myjson?.pincode?.state
        }
      } catch (err) {
        throw err;
      }
  };

export const Rate = (data) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.RATE_INIT,
    });
    const newData = {
      emailId: data.email,
      sessionKey: data.st,
      fromPincode: data.pickupPincode,
      orderType: data.orderType,
      paymentMethod: data.paymentMode,
      productMrp: data.declaredValue,
      shippingHeightCms: data.height,
      shippingLengthCms: data.length,
      shippingWeightKg: data.weight,
      shippingWidthCms: data.width,
      toPincode: data.destinationPincode,
    };
    try {
      const myresponse = await fetch("https://api.zupptravel.com/WWGetRates", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newData),
      });
      const myjson = await myresponse.json();
      console.log("myresponse", myjson);

      dispatch({
        type: actionTypes.RATE,
        payload: myjson,
      });
    } catch (err) {
      throw err;
    }
  };
};
export const FinalAmount = async (data) => {
  const mydata = JSON.parse(sessionStorage.getItem("userData"));
  const FromPincode = JSON.parse(data.address).pincode;
  const shippingWeight =
    (data.shippingLength * data.shippingWidth * data.shippingHeight) / 5000;
  const newData = {
    emailId: mydata.user.emailId,
    sessionKey: mydata.code,
    fromPincode: FromPincode,
    orderType: data.orderType,
    paymentMethod: data.paymentOrderType,
    productMrp: data.productValue <= 1000 ? 1000 : data.productValue,
    shippingHeightCms: data.shippingHeight,
    shippingLengthCms: data.shippingLength,
    shippingWeightKg:
      data.physicalWeight >= shippingWeight
        ? data.physicalWeight
        : shippingWeight,
    shippingWidthCms: data.shippingWidth,
    toPincode: data.custPinCode,
  };
  console.log(newData);
  const myresponse = await fetch("https://api.zupptravel.com/WWGetRates", {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(newData),
  });
  const myjson = await myresponse.json();
  return myjson;
};
export const refresh = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: actionTypes.LOGOUT,
      });
    } catch (err) {
      throw err;
    }
  };
};

export const Wallet = (data) => {
  // console.log(data);
  return async (dispatch) => {
    dispatch({
      type: actionTypes.WALLET_INIT,
    });
    try {
      const myresponse = await fetch(
        "https://api.zupptravel.com/WWGetAddress",
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },

          body: JSON.stringify({ userId: data }),
        }
      );
      const myjson = await myresponse.json();
      dispatch({
        type: actionTypes.WALLET,
        payload:
          myjson.user != null
            ? myjson.user.walletTotalAmount
            : JSON.parse(sessionStorage.getItem("userData")).user
                .walletTotalAmount,
      });
    } catch (err) {
      throw err;
    }
  };
};

//ORDER PLACE

export const Order = async (data) => {
  const mydata = {
    ...data,
    volumetricWeight:
      (data.shippingLength * data.shippingWidth * data.shippingHeight) / 5000,
    sessionKey: JSON.parse(sessionStorage.getItem("userData")).code,
    productValue: data.declaredValue,
  };
  console.log(mydata);
  const myresponse = await fetch("https://api.zupptravel.com/placeOrder", {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },

    body: JSON.stringify(mydata),
  });
  const myjson = await myresponse.json();
  return myjson;
};

export const Register = async (data) => {
  const myresponse = await fetch("https://api.zupptravel.com/WWRegisterUser", {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  const myjson = await myresponse.json();
  return myjson.successful;
};

export const Tracking = (mydata) => {
  console.log(mydata);

  return async (dispatch) => {
    dispatch({
      type: actionTypes.TRACKING_INIT,
    });
    try {
      const myresponse = await fetch(
        "https://manage.ithinklogistics.com/api_v3/order/track.json",
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            data: {
              access_token: "4725915e3905f4d3eafc7d47ba899e11",
              secret_key: "5c2df44975a966bbf70a325a61c9c70d",
              awb_number_list: mydata,
            },
          }),
        }
      );
      const myjson = await myresponse.json();
      console.log("myresponse", Object.values(myjson.data)[0]);

      dispatch({
        type: actionTypes.TRACKING,
        payload: Object.values(myjson.data)[0],
      });
    } catch (err) {
      throw err;
    }
  };
};

export const Shipment = (mydata) => {
  console.log(mydata);

  return async (dispatch) => {
    dispatch({
      type: actionTypes.PRINTSHIPMENT_INIT,
    });
    try {
      const myresponse = await fetch(
        "https://manage.logistics.com/api_v3/shipping/label.json",
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            data: {
              access_token: "4725915e3905f4d3eafc7d47ba899e11",
              secret_key: "5c2df44975a966bbf70a325a61c9c70d",
              awb_numbers: mydata,
              page_size: "A4",
              display_cod_prepaid: "",
              display_shipper_mobile: "",
              display_shipper_address: "",
            },
          }),
        }
      );
      const myjson = await myresponse.json();
      console.log("myresponse", myjson);

      dispatch({
        type: actionTypes.PRINTSHIPMENT,
        payload: myjson,
      });
    } catch (err) {
      throw err;
    }
  };
};

export const AllOrders = (data) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.ALLORDERS_INIT,
    });
    try {
      const myresponse = await fetch(
        "https://api.zupptravel.com/getAllOrderDetails",
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },

          body: JSON.stringify(data),
        }
      );
      const myjson = await myresponse.json();
      console.log(myjson);
      dispatch({
        type: actionTypes.ALLORDERS,
        payload: myjson,
      });
    } catch (err) {
      throw err;
    }
  };
};
//for a partiular order:- =>
export const OrderDetails = (data) => {
  console.log(data);
  return async (dispatch) => {
    dispatch({
      type: actionTypes.DETAILS_INIT,
    });
    try {
      const myresponse = await fetch(
        "https://api.zupptravel.com/getOrderDetails",
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      const myjson = await myresponse.json();
      console.log("myresponse", myjson);

      dispatch({
        type: actionTypes.DETAILS,
        payload: myjson,
      });
    } catch (err) {
      throw err;
    }
  };
};

export const Cancel = (data) => {
  console.log(data);
  return async (dispatch) => {
    dispatch({
      type: actionTypes.CANCEL_INIT,
    });
    try {
      const myresponse = await fetch("https://api.zupptravel.com/cancelOrder", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      const myjson = await myresponse.json();
      console.log("myresponse", myjson);

      dispatch({
        type: actionTypes.CANCEL,
        payload: myjson,
      });
    } catch (err) {
      throw err;
    }
  };
};

export const WList = () => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.WAREHOUSELIST_INIT,
    });
    try {
      const myresponse = await fetch(
        "https://api.zupptravel.com/WWGetAllAddress",
        {
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log(myresponse);
      const myjson = await myresponse.json();
      console.log("myresponse", myjson);

      dispatch({
        type: actionTypes.WAREHOUSELIST,
        payload: myjson,
      });
    } catch (err) {
      throw err;
    }
  };
};
export const OList = (data) => {
  console.log(data);
  return async (dispatch) => {
    dispatch({
      type: actionTypes.TOTALORDERS_INIT,
    });
    try {
      const myresponse = await fetch(
        "https://api.zupptravel.com/getAllOrderDetails",
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const myjson = await myresponse.json();
      console.log("myresponse", myjson);

      dispatch({
        type: actionTypes.TOTALORDERS,
        payload: myjson,
      });
    } catch (err) {
      throw err;
    }
  };
};

export const SuperLogin = (data) => {
  console.log(data);
  return async (dispatch) => {
    dispatch({
      type: actionTypes.SUPERLOGIN_INIT,
    });
    try {
      const myresponse = await fetch(
        "https://api.zupptravel.com/LoginSuperAdmin",
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      const myjson = await myresponse.json();
      console.log("myresponse", myjson);

      dispatch({
        type: actionTypes.SUPERLOGIN,
        payload: myjson,
      });
    } catch (err) {
      throw err;
    }
  };
};

export const AddPricing = (data) => {
  console.log(data);
  return async (dispatch) => {
    dispatch({
      type: actionTypes.ADDPRICING_INIT,
    });
    try {
      const myresponse = await fetch("https://api.zupptravel.com/addPricing", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      const myjson = await myresponse.json();
      console.log("myresponse", myjson);

      dispatch({
        type: actionTypes.ADDPRICING,
        payload: myjson,
      });
    } catch (err) {
      throw err;
    }
  };
};
export const Createcoupon = async (mydata) => {

    const token = localStorage.getItem('token');
    const authHeader = `Bearer ${token}`

  const myresponse = await fetch("https://api.zupptravel.com/api/v1/coupon/newCoupon", {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      'Authorization': authHeader
    },
    body: JSON.stringify(mydata),
  });
  const myjson = await myresponse.json();
  return myjson;
};

export const OTP = (data) => {
  console.log(data);
  return async (dispatch) => {
    dispatch({
      type: actionTypes.OTP_INIT,
    });
    try {
      const myresponse = await fetch(
        "https://api.zupptravel.com/otpController/sendOtp",
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userValue: "9560624428",
            usertype: "mobile",
          }),
        }
      );
      const myjson = await myresponse.json();
      console.log("myresponse", myjson);

      dispatch({
        type: actionTypes.OTP,
        payload: myjson,
      });
    } catch (err) {
      throw err;
    }
  };
};

export const SendOTP = async (data) => {
  const myresponse = await fetch(
    "https://api.zupptravel.com/otpController/sendOtp",
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userValue: data.phone,
        usertype: "mobile",
      }),
    }
  );
  return myresponse;
};
export const VerifyOTP = async (data) => {
  const myresponse = await fetch(
    "https://api.zupptravel.com/otpController/verifyOtp",
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        otp: data.otp,
        value: data.phone,
        type: "mobile",
      }),
    }
  );
  return myresponse;
};
export const OTPVerify = (data) => {
  console.log(data);
  return async (dispatch) => {
    dispatch({
      type: actionTypes.OTPVERIFY_INIT,
    });
    try {
      const myresponse = await fetch(
        "https://api.zupptravel.com/otpController/verifyOtp",
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            otp: data,
            value: "9560624428",
            type: "mobile",
          }),
        }
      );
      const myjson = await myresponse.json();
      console.log("myresponse", myjson);

      dispatch({
        type: actionTypes.OTPVERIFY,
        payload: myjson,
      });
    } catch (err) {
      throw err;
    }
  };
};
export const GetAPI = (type) => {
  console.log(type);
  return async (dispatch) => {
    try {
      const newData = {
        emailId: "",
        sessionKey: "",
        type: type,
      };
      const myresponse = await fetch(
        "https://api.zupptravel.com/APIManagement/getAllAPI",
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newData),
        }
      );
      const myjson = await myresponse.json();
      console.log(myjson);
      if (myjson.successful) {
        dispatch({
          type: actionTypes.GETAPIDATA,
          payload: myjson.allAPIDetails,
        });
      }
    } catch (err) {
      throw err;
    }
  };
};
export const GetBankDetails = () => {
  return async (dispatch) => {
    try {
      const newData = {
        emailId: "string",
        sessionKey: "string",
      };
      const myresponse = await fetch(
        "https://api.zupptravel.com/PaymentManagement/getAllBanks",
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newData),
        }
      );
      const myjson = await myresponse.json();
      console.log(myjson);
      if (myjson.successful) {
        const data = {
          allPaymentGateways: myjson.allPaymentGateways,
          allBankDetails: myjson.allBankDetails,
        };
        dispatch({
          type: actionTypes.PAYMENTMANAGEMENT,
          payload: data,
        });
      }
    } catch (err) {
      throw err;
    }
  };
};
export const AddGift = async (newData) => {
  const myresponse = await fetch("https://api.zupptravel.com/addGiftCard", {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(newData),
  });
  return myresponse.json();
};
export const GetGift = async (newData) => {
  const myresponse = await fetch("https://api.zupptravel.com/getGiftCard", {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(newData),
  });
  return myresponse.json();
};
export const DeleteGift = async (newData) => {
  const myresponse = await fetch("https://api.zupptravel.com/deleteGiftCard", {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(newData),
  });
  return myresponse.json();
};
export const AddGiftCategory = async (newData) => {
  const myresponse = await fetch("https://api.zupptravel.com/addGiftCategory", {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(newData),
  });
  return myresponse.json();
};
export const GetGiftCategory = async (newData) => {
  const myresponse = await fetch("https://api.zupptravel.com/getGiftCategory", {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(newData),
  });
  return myresponse.json();
};
export const DeleteGiftCategory = async (newData) => {
  const myresponse = await fetch(
    "https://api.zupptravel.com/deleteGiftCategory",
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newData),
    }
  );
  return myresponse.json();
};
export const AddBLOGCategory = async (newData) => {
  const token = localStorage.getItem('token');
  const authHeader = `Bearer ${token}`
  const myresponse = await fetch("https://api.zupptravel.com/api/v1/blogCategory/addBlogCategory", {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      'Authorization': authHeader
    },
    body: JSON.stringify(newData),
  });
  const res = await myresponse.json();
  if (res?.success) {
    return "success";
  } else {
    return "fail";
  }
};

export const GetBLOGCategory = async () => {
  const token = localStorage.getItem('token');
  const authHeader = `Bearer ${token}`;
  const myresponse = await fetch("https://api.zupptravel.com/api/v1/blogCategory/getBlogCategories", {
    method: "get",
    headers: {
      "Content-Type": "application/json",
      'Authorization': authHeader
    },
  });
  return myresponse.json();
};

export const DeleteBLOGCategory = async (id) => {
    const token = localStorage.getItem('token');
    const authHeader = `Bearer ${token}`;
    const myresponse = await fetch(`https://api.zupptravel.com/api/v1/blogCategory/deleteBlogCategory/${id}`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        'Authorization': authHeader
      },
    });
    return myresponse.json();
  };

  export const AddBLOGSubCategory = async (newData) => {
    const token = localStorage.getItem('token');
    const authHeader = `Bearer ${token}`
    const myresponse = await fetch("https://api.zupptravel.com/api/v1/blogSubCategory/addBlogSubCategory", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        'Authorization': authHeader
      },
      body: JSON.stringify(newData),
    });
    const res = await myresponse.json();
    if (res?.success) {
      return "success";
    } else {
      return "fail";
    }
  };
  
  export const GetBLOGSubCategory = async () => {
    const token = localStorage.getItem('token');
    const authHeader = `Bearer ${token}`;
    const myresponse = await fetch("https://api.zupptravel.com/api/v1/blogSubCategory/getBlogSubCategories", {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        'Authorization': authHeader
      },
    });
    return myresponse.json();
  };
  
  export const DeleteBLOGSubCategory = async (id) => {
      const token = localStorage.getItem('token');
      const authHeader = `Bearer ${token}`;
      const myresponse = await fetch(`https://api.zupptravel.com/api/v1/blogSubCategory/deleteBlogSubCategory/${id}`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          'Authorization': authHeader
        },
      });
      return myresponse.json();
    };

export const AddBlog = async (newData) => {
  const token = localStorage.getItem('token');
  const authHeader = `Bearer ${token}`
  const myresponse = await fetch("https://api.zupptravel.com/api/v1/blog/addBlog", {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      'Authorization': authHeader
    },
    body: JSON.stringify(newData),
  });
  return myresponse.json();
};
export const GetBlog = async () => {
  const token = localStorage.getItem('token');
  const authHeader = `Bearer ${token}`
  const myresponse = await fetch("https://api.zupptravel.com/api/v1/blog/getBlogs", {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      'Authorization': authHeader
    },
  });
  return myresponse.json();
};

export const DeleteBlog = async (id) => {
  const token = localStorage.getItem('token');
  const authHeader = `Bearer ${token}`
  const myresponse = await fetch(`https://api.zupptravel.com/api/v1/blog/deleteBlog/${id}`, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      'Authorization': authHeader
    },
  });
  return myresponse.json();
};

export const AddCategory = async (value) => {
  const newData = {
    category: value,
  };
  const myresponse = await fetch(
    "https://api.zupptravel.com/suppliercategory",
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newData),
    }
  );
  if (myresponse.status == 200) {
    return "success";
  } else {
    return "fail";
  }
};
export const AddSubCategory = async (value, id) => {
  const newData = {
    categoryId: id,
    supplierSubCategory: value,
  };
  const myresponse = await fetch(
    "https://api.zupptravel.com/suppliersubcategory",
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newData),
    }
  );
  if (myresponse.status == 200) {
    return "success";
  } else {
    return "fail";
  }
};
export const UpdateWarehouseStatus = async (data) => {
  const myresponse = await fetch(
    "https://api.zupptravel.com/WWWarehouseFlipStatus",
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );
  const myjson = await myresponse.json();
  console.log(myjson);
  if (myjson.successful) {
    return "success";
  } else {
    return "fail";
  }
};
export const SendDeliveryPricing = async (data) => {
  const myresponse = await fetch("https://api.zupptravel.com/addPricing", {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  const myjson = await myresponse.json();
  console.log(myjson);
  if (myjson.successful) {
    return "success";
  } else {
    return "fail";
  }
};
export const RequestRechargeApi = async (data) => {
  const myresponse = await fetch(
    "https://api.zupptravel.com/APIManagement/sendEmailAPIManagement",
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );
  const myjson = await myresponse.json();
  console.log(myjson);
  if (myjson.successful) {
    return "success";
  } else {
    return "fail";
  }
};

export const WalletOffer = async (data) => {
  const myresponse = await fetch("https://api.zupptravel.com/addWalletOffers", {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  const myjson = await myresponse.json();
  console.log(myjson);
  return myjson.successful;
};

export const CDMPromotionalOffer = async (data) => {
  const myresponse = await fetch(
    "https://api.zupptravel.com/addCDMPromotionalOffers",
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );
  const myjson = await myresponse.json();
  console.log(myjson);
  return myjson.successful;
};

export const PromotionalOffer = async (data) => {
  const myresponse = await fetch(
    "https://api.zupptravel.com/addPromotionalOffers",
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );
  const myjson = await myresponse.json();
  console.log(myjson);
  return myjson.successful;
};

export const UploadLogo = async (data) => {
  const token = localStorage.getItem('token');
  const authHeader = `Bearer ${token}`;
  const myresponse = await fetch("https://api.zupptravel.com/api/v1/adminMeta/addUpdateLogoDetails", {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      'Authorization': authHeader
    },
    body: JSON.stringify(data),
  });
  const myjson = await myresponse.json();

  return myjson.success;
};

export const UploadFavicon = async (data) => {
    const token = localStorage.getItem('token');
  const authHeader = `Bearer ${token}`;
  const myresponse = await fetch("https://api.zupptravel.com/api/v1/adminMeta/addUpdateFaviconDetails", {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      'Authorization': authHeader
    },
    body: JSON.stringify(data),
  });
  const myjson = await myresponse.json();
  console.log(myjson);
  return myjson.successful;
};

export const GetActiveCoupon = async () => {
  const myresponse = await fetch("https://api.zupptravel.com/api/v1/coupon/getCoupons", {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({}),
  });
  const myjson = await myresponse.json();

  return myjson;
};

export const GetStoreList = async (data) => {
  const myresponse = await fetch("https://api.zupptravel.com/getAllStore", {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  const myjson = await myresponse.json();

  return myjson.allstores;
};

export const AddRecharge = async (data) => {
  const myresponse = await fetch(
    "https://api.zupptravel.com/addRechargeOffer",
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );
  const myjson = await myresponse.json();

  return myjson;
};

export const AddSliderImageCustomer = async (data) => {
  const myresponse = await fetch(
    "https://api.zupptravel.com/addHomepageSliderCustomer",
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );
  const myjson = await myresponse.json();

  return myjson;
};

export const AddHomePageCenterImage = async (data) => {
  const myresponse = await fetch(
    "https://api.zupptravel.com/addHomepageCenterCustomer",
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );
  const myjson = await myresponse.json();

  return myjson;
};

export const AddSliderImage = async (data) => {
  const myresponse = await fetch(
    "https://api.zupptravel.com/addHomepageSliderCustomer",
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );
  const myjson = await myresponse.json();

  return myjson;
};

export const GetHomePageCenterImage = async (data) => {
  const myresponse = await fetch(
    "https://api.zupptravel.com/getHomepageCenterCustomer",
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );
  const myjson = await myresponse.json();

  return myjson;
};

export const UpdateWareHouseAndUser = async (data) => {
  const myresponse = await fetch("https://api.zupptravel.com/WWUpdateUser", {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  const myjson = await myresponse.json();

  return myjson;
};

export const GetVendor = async () => {
  const myresponse = await fetch(
    "https://api.zupptravel.com/vendormanagement",
    {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const myjson = await myresponse.json();
  return myjson;
};

export const EmailInformation = async (data) => {
  const myresponse = await fetch("https://api.zupptravel.com/createEmail", {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  const myjson = await myresponse.json();
  return myjson;
};

export const SmsInformation = async (data) => {
  const myresponse = await fetch("https://api.zupptravel.com/createSMS", {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  const myjson = await myresponse.json();
  return myjson;
};

export const GetSms = async () => {
  const myresponse = await fetch("https://api.zupptravel.com/getSMS", {
    method: "get",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const myjson = await myresponse.json();
  return myjson;
};

export const GetRechargeOffer = async (data) => {
  const myresponse = await fetch(
    "https://api.zupptravel.com/getRechargeOffer",
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );
  const myjson = await myresponse.json();
  return myjson;
};

export const GetHomepageSliderCustomer = async (data) => {
  const myresponse = await fetch(
    "https://api.zupptravel.com/getHomepageSliderCustomer",
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );
  const myjson = await myresponse.json();

  return myjson;
};

export const GetHomepageSliderSupplier = async (data) => {
  const myresponse = await fetch(
    "https://api.zupptravel.com/getHomepageSliderSupplier",
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );
  const myjson = await myresponse.json();

  return myjson;
};

export const AddStaffData = async (data) => {
  const myresponse = await fetch("https://api.zupptravel.com/registerStaff", {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  const myjson = await myresponse.json();

  return myjson;
};

export const GetStaffList = async (data) => {
  const myresponse = await fetch("https://api.zupptravel.com/getAllStaff", {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  const myjson = await myresponse.json();

  return myjson;
};

export const GetSocialLinks = async () => {
  const myresponse = await fetch("https://api.zupptravel.com/api/v1/social/getSocialLinks", {
    method: "get",
    headers: {
      "Content-Type": "application/json",
    }
  });
  const myjson = await myresponse.json();

  return myjson;
};

export const AddDepartment = async (data) => {
  const myresponse = await fetch("https://api.zupptravel.com/departments/add", {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  return myresponse.status;
};
export const AddDesignation = async (data) => {
  const myresponse = await fetch(
    "https://api.zupptravel.com/designations/add",
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );
  return myresponse.status;
};

export const GetDepartment = async (data) => {
  const myresponse = await fetch("https://api.zupptravel.com/departments", {
    method: "get",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const json = await myresponse.json();
  return json;
};
export const GetDesignation = async (data) => {
  const myresponse = await fetch("https://api.zupptravel.com/designations", {
    method: "get",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const json = await myresponse.json();
  return json;
};

export const DeleteDepartment = async (id) => {
  const myresponse = await fetch(
    `https://api.zupptravel.com/departments?id=${id}`,
    {
      method: "post",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  );
  return myresponse;
};
export const DeleteDesignation = async (id) => {
  const myresponse = await fetch(
    `https://api.zupptravel.com/designations?id=${id}`,
    {
      method: "post",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  );
  return myresponse;
};

export const PrintInvoice = async (data) => {
  const myresponse = await fetch(
    "https://api.zupptravel.com/Invoice/getInvoiceByAwbNo",
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );
  return myresponse;
};

export const ChangePasswordAPI = async (data) => {
  const myresponse = await fetch(
    "https://api.zupptravel.com/changePasswordWarehouse",
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );
  const json = await myresponse.json();
  return json.successful;
};

export const GetTransactions = async (data) => {
  const myresponse = await fetch("https://api.zupptravel.com/getTransactions", {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  const json = await myresponse.json();
  return json;
};

export const CreateVendor = async (data) => {
  const myresponse = await fetch(
    "https://api.zupptravel.com/vendormanagement",
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );
  const json = await myresponse.json();
  return json;
};

export const GetSupplierById = async (id) => {

  console.log("id of supplier",id)
  const myresponse = await fetch(
    // `https://api.zupptravel.com/suppliermanagement/${id}`,
    `https://api.zupptravel.com/vendormanagement/${id}`,

    {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const json = await myresponse.json();
  return json;
};

export const GetContactInfo = async (data) => {
  const myresponse = await fetch("https://api.zupptravel.com/api/v1/contact/getContactInfo", {
    method: "get",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  const json = await myresponse.json();
  return json;
};

export const GetAdminBookings = async (data) => {
    const token = localStorage.getItem('token');
    const authHeader = `Bearer ${token}`;
    const myresponse = await fetch("https://api.zupptravel.com/api/v1/booking/getAllBookingsForAdmin", {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        'Authorization': authHeader
      }
    });
    const json = await myresponse.json();
    return json;
  };

export const GetMetaDetails = async () => {
  const myresponse = await fetch(`https://api.zupptravel.com/api/v1/meta/getMetaDetails`, {
    method: "get",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const json = await myresponse.json();
  return json;
};

export const GetNewsletter = async () => {
  const token = localStorage.getItem('token');
  const authHeader = `Bearer ${token}`
  const myresponse = await fetch(
    `https://api.zupptravel.com/api/v1/newsletter/getAllNewsLetters`,
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        'Authorization': authHeader
      },
    }
  );
  const json = await myresponse.json();
  return json;
};

export const AddNewsletterUser = async (data) => {
    const token = localStorage.getItem('token');
    const authHeader = `Bearer ${token}`
    const myresponse = await fetch(
      `https://api.zupptravel.com/api/v1/newsletter/addNewsLetter`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          'Authorization': authHeader
        },
        body: JSON.stringify(data),
      }
    );
    const json = await myresponse.json();
    return json;
  };
  

export const DeleteCoupon = async (data) => {
    const token = localStorage.getItem('token');
    const authHeader = `Bearer ${token}`;
  const myresponse = await fetch(`https://api.zupptravel.com/api/v1/coupon/deleteCoupon`, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      'Authorization': authHeader
    },
    body: JSON.stringify(data),
  });
  const json = await myresponse.json();
  return json;
};

export const UpdateCoupon = async (data) => {
  const myresponse = await fetch(`https://api.zupptravel.com/api/v1/coupon/updateCoupon`, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  const json = await myresponse.json();
  return json;
};

export const UpdateStaff = async (data) => {
  const myresponse = await fetch(`https://api.zupptravel.com/update`, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  const json = await myresponse.json();
  return json;
};

export const ContactInfoDelete = async (id) => {
  const myresponse = await fetch(
    `https://api.zupptravel.com/deleteContactInfo/${id}`,
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const json = await myresponse.json();
  return json;
};

export const HomePageCenterDelete = async (data) => {
  const myresponse = await fetch(
    `https://api.zupptravel.com/deleteHomepageCenterCustomer`,
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );
  const json = await myresponse.json();
  return json;
};

export const RechargeOfferDelete = async (data) => {
  const myresponse = await fetch(
    `https://api.zupptravel.com/deleteRechargeOffer`,
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );
  const json = await myresponse.json();
  return json;
};

export const SliderCustomerDelete = async (data) => {
  const myresponse = await fetch(
    `https://api.zupptravel.com/deleteHomepageSliderCustomer`,
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );
  const json = await myresponse.json();
  return json;
};

export const SliderSupplierDelete = async (data) => {
  const myresponse = await fetch(
    `https://api.zupptravel.com/deleteHomepageSliderSupplier`,
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );
  const json = await myresponse.json();
  return json;
};

export const WalletOfferDelete = async (data) => {
  const myresponse = await fetch(
    `https://api.zupptravel.com/deleteWalletOffers`,
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );
  const json = await myresponse.json();
  return json;
};
export const FlipStoreStatus = async (data) => {
  const myresponse = await fetch(
    `https://api.zupptravel.com/activeInactiveStore`,
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );
  const json = await myresponse.json();
  return json;
};
export const SendDeliveryPricingAmount = async (data) => {
  const myresponse = await fetch(`https://api.zupptravel.com/deliverypricing`, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  return myresponse;
};
export const GetDeliveryPricingAmount = async () => {
  const myresponse = await fetch(`https://api.zupptravel.com/deliverypricing`, {
    method: "get",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const json = await myresponse.json();
  return json;
};
export const DeleteDeliveryPricingAmount = async (id) => {
  const myresponse = await fetch(
    `https://api.zupptravel.com/deletedeliverypricing/${id}`,
    {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return myresponse;
};
export const CreateSupplierInformation = async (data) => {
  const myresponse = await fetch(
    `https://api.zupptravel.com/addSuppliersWarehouseInformation`,
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );
  return myresponse;
};

export const GetLogo = async () => {
  const token = localStorage.getItem('token');
  const authHeader = `Bearer ${token}`
  const myresponse = await fetch(`https://api.zupptravel.com/api/v1/adminMeta/getLogo`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      'Authorization': authHeader
    },
  });
  const json = await myresponse.json();
  return json;
};

export const OrderTrackingAdminPanel = async (mydata) => {
  const myresponse = await fetch(
    "https://manage.ithinklogistics.com/api_v3/order/track.json",
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: {
          access_token: "4725915e3905f4d3eafc7d47ba899e11",
          secret_key: "5c2df44975a966bbf70a325a61c9c70d",
          awb_number_list: mydata,
        },
      }),
    }
  );
  const myjson = await myresponse.json();

  return Object.values(myjson.data)[0];
};

export const GetSupplierWarehouses = async () => {
  const myresponse = await fetch(
    `https://api.zupptravel.com/getAllSuppliersWarehouseInformation`,
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const json = await myresponse.json();
  return json;
};
export const AddSubVariant = async (name, id) => {
  const newData = {
    subvariant: name,
    variantid: id,
  };
  const myresponse = await fetch("https://api.zupptravel.com/subvariants", {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(newData),
  });
  if (myresponse.status == 200) {
    return "success";
  } else {
    return "fail";
  }
};
export const AddVarient = async (value) => {
  const newData = {
    variant: value,
  };
  const myresponse = await fetch("https://api.zupptravel.com/variants", {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(newData),
  });
  if (myresponse.status == 200) {
    return "success";
  } else {
    return "fail";
  }
};
export const FlipStaffStatus = async (data) => {
  const myresponse = await fetch(
    `https://api.zupptravel.com/changeActiveStatus`,
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );
  const json = await myresponse.json();
  return json;
};

export const GetVariant = async () => {
  const myresponse = await fetch("https://api.zupptravel.com/variants", {
    method: "get",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const json = await myresponse.json();
  if (json != null) {
    return json;
  } else {
    return [];
  }
};
export const GetSubVariant = async () => {
  const myresponse = await fetch(
    "https://api.zupptravel.com/fetchallsubvariants",
    {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const json = await myresponse.json();
  if (json != null) {
    return json;
  } else {
    return [];
  }
};
export const GetStoreById = async (id) => {
  const myresponse = await fetch(
    `https://api.zupptravel.com/getallStorebysupplierid/${id}`,
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const json = await myresponse.json();
  return json;
};

export const HandleSupplierStatus = async (status, id) => {
  const myresponse = await fetch(
    `https://api.zupptravel.com/updatevendormanagementstatus/${id}/${status}`,
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return myresponse;
};

export const UpdateSupplierData = async (data, id) => {
  const myresponse = await fetch(
    `https://api.zupptravel.com/suppliermanagement/${id}`,
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );
  return myresponse;
};

export const GetFavIcon = async (data) => {
  const myresponse = await fetch(`https://api.zupptravel.com/getFavicon`, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  const json = await myresponse.json();
  return json;
};

export const GetCategory = async () => {
  const myresponse = await fetch(
    "https://api.zupptravel.com/suppliercategory",
    {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const myjson = await myresponse.json();
  return myjson;
};

export const GetSubCategory = async () => {
  const myresponse = await fetch(
    "https://api.zupptravel.com/fetchallsuppliersubcategory",
    {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const myjson = await myresponse.json();
  return myjson;
};

export const AddGoodsData = async (data) => {
  const myresponse = await fetch(`https://api.zupptravel.com/addProductGoods`, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  const json = await myresponse.json();
  return json;
};

export const AddServicesData = async (data) => {
  const myresponse = await fetch(`https://api.zupptravel.com/prodservice`, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  if (myresponse.status == 200) {
    return true;
  } else {
    return false;
  }
};

export const DeleteCategory = async (data) => {
  const myresponse = await fetch(
    `https://api.zupptravel.com/suppliercategory/${data}`,
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  if (myresponse.status == 200) {
    return true;
  } else {
    return false;
  }
};
export const DeleteSubCategory = async (data) => {
  const myresponse = await fetch(
    `https://api.zupptravel.com/suppliersubcategory/${data}`,
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  if (myresponse.status == 200) {
    return true;
  } else {
    return false;
  }
};
export const DeleteVariant = async (data) => {
  const myresponse = await fetch(
    `https://api.zupptravel.com/variants/${data}`,
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  if (myresponse.status == 200) {
    return true;
  } else {
    return false;
  }
};
export const DeleteSubVariant = async (data) => {
  const myresponse = await fetch(
    `https://api.zupptravel.com/subvariants/${data}`,
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  if (myresponse.status == 200) {
    return true;
  } else {
    return false;
  }
};

export const DeleteProductCategoryData = async (data) => {
  const myresponse = await fetch(
    `https://api.zupptravel.com/deleteproductcategoryname/${data}`,
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return await myresponse.json();
};

export const AddProductCategoryData = async (data) => {
  const myresponse = await fetch(
    `https://api.zupptravel.com/addproductcategory`,
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );
  if (myresponse.status == 200) {
    return true;
  } else {
    return false;
  }
};

export const GetProductCategoryList = async () => {
  const myresponse = await fetch(
    "https://api.zupptravel.com/getallproductcategoryname",
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const myjson = await myresponse.json();
  return myjson;
};
export const Addairport = async (newData) => {
  const token = localStorage.getItem('token');
  const authHeader = `Bearer ${token}`
  const myresponse = await fetch("https://api.zupptravel.com/api/v1/airport/addAirport", {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      'Authorization': authHeader
    },
    body: JSON.stringify(newData),
  });
  const myjson = await myresponse.json();
  console.log(myjson);
  return myjson;
};
export const addSightSeeing = async (data) => {
  const token = localStorage.getItem('token');
  const authHeader = `Bearer ${token}`
  const myresponse = await fetch("https://api.zupptravel.com/api/v1/sightseeing/addSightSeeing", {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      'Authorization': authHeader
    },
    body: JSON.stringify(data),
  });
  return myresponse.json();
};
export const UpdateSightSeeing = async (data) => {
  console.log(data);
  const myresponse = await fetch(
    "https://api.zupptravel.com/updateSightSeeing",
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data }),
    }
  );
  return myresponse.json();
};

export const GetSightseeingList = async () => {
    const token = localStorage.getItem('token');
    const authHeader = `Bearer ${token}`
    const myresponse = await fetch("https://api.zupptravel.com/api/v1/sightseeing/getSightSeeing", {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      'Authorization': authHeader
    },
  });

  return myresponse.json();
};

export const DeleteSightseeing = async (id) => {
    const token = localStorage.getItem('token');
    const authHeader = `Bearer ${token}`
  const myresponse = await fetch(
    `https://api.zupptravel.com/api/v1/sightseeing/deleteSightSeeing/${id}`,
    {
      method: "delete",
      headers: {
        "Content-Type": "application/json",
        'Authorization': authHeader
      }
    }
  );

  return myresponse.json();
};

export const CityState = async (mydata) => {
  console.log(mydata);
  const data = {
    data: {
      pincode: mydata,
      access_token: "4725915e3905f4d3eafc7d47ba899e11",
      secret_key: "5c2df44975a966bbf70a325a61c9c70d",
    },
  };
  const myresponse = await fetch(
    "https://manage.ithinklogistics.com/api_v3/pincode/check.json",
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );
  return myresponse.json();
};

export const ADDCurrency = async (data) => {
  console.log("!!!!!", JSON.stringify(data));
  const myresponse = await fetch("https://api.zupptravel.com/addCurrency", {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  return myresponse.json();
};
export const GetCurrencyList = async (data) => {
  console.log("!!!!!", data);
  const myresponse = await fetch("https://api.zupptravel.com/getCurrency", {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      emailId: data.data.key,
      password: "string",
      phoneno: "string",
      sessionKey: data.sessionId,
    }),
  });
  return myresponse.json();
};
export const DeleteCurrency = async (data) => {
  console.log("!!!!!", data);
  const myresponse = await fetch("https://api.zupptravel.com/deleteCurrency", {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  return myresponse.json();
};
export const AddAddressCustomer = async (data) => {
  const myresponse = await fetch(`https://api.zupptravel.com/addNewAddress`, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  const json = await myresponse.json();
  return json;
};
export const AddDeductEarning = async (data, type) => {
  let url;
  if (type === "E-wallet") {
    url = `https://api.zupptravel.com/Customer/EWallet`;
  } else if (type === "Play Money") {
    url = `https://api.zupptravel.com/Customer/PlayMoney`;
  } else {
    url = `https://api.zupptravel.com/Customer/CashBack`;
  }
  const myresponse = await fetch(url, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  const json = await myresponse.json();
  return json;
};
export const GetCustomers = async () => {
  const token = localStorage.getItem('token');
  const authHeader = `Bearer ${token}`
  const myresponse = await fetch(
    "https://api.zupptravel.com/api/v1/user/getAllCustomers",
    {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        'Authorization': authHeader
      },
    }
  );
  const myjson = await myresponse.json();
  return myjson;
};
export const HandleDeleteAddressCustomer = async (data) => {
  const myresponse = await fetch(
    `https://api.zupptravel.com/deleteCustomerAddress`,
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );
  const json = await myresponse.json();
  return json;
};
export const UpdateAddressCustomer = async (data) => {
  const myresponse = await fetch(`https://api.zupptravel.com/updateaddress`, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  const json = await myresponse.json();
  return json;
};
export const AddMarkup = async (type, data) => {
  let url;
  const token = localStorage.getItem('token');
  const authHeader = `Bearer ${token}`

  if (type === "flight") {
    url = `https://api.zupptravel.com/api/v1/markup/addFlightMarkup`;
  } else if (type === "Bus") {
    url = `https://api.zupptravel.com/addBusMarkup`;
  } else if (type === "Hotel") {
    url = `https://api.zupptravel.com/addHotelMarkup`;
  } else if (type === "Cab") {
    url = `https://api.zupptravel.com/addCabMarkup`;
  } else {
    url = `https://api.zupptravel.com/addInsuranceMarkup`;
  }

  const myresponse = await fetch(url, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      'Authorization': authHeader
    },
    body: JSON.stringify(data),
  });
  return myresponse.json();
};
export const GetMarkup = async (type) => {
  let url;
  const token = localStorage.getItem('token');
  const authHeader = `Bearer ${token}`

  if (type === "flight") {
    url = `https://api.zupptravel.com/api/v1/markup/getAllFlightMarkup`;
  } else if (type === "Bus") {
    url = `https://api.zupptravel.com/getBusMarkup`;
  } else if (type === "Hotel") {
    url = `https://api.zupptravel.com/getHotelMarkup`;
  } else if (type === "Cab") {
    url = `https://api.zupptravel.com/getCabMarkup`;
  } else {
    url = `https://api.zupptravel.com/getInsuranceMarkup`;
  }

  const myresponse = await fetch(url, {
    method: "get",
    headers: {
      "Content-Type": "application/json",
      'Authorization': authHeader
    }
  });
  return myresponse.json();
};

export const DeleteMarkup = async (id) => {
    const token = localStorage.getItem('token');
    const authHeader = `Bearer ${token}`
  const myresponse = await fetch(
    `api/v1/markup/deleteFlightMarkup/${id}`,
    {
      method: "delete",
      headers: {
        "Content-Type": "application/json",
        'Authorization': authHeader
      }
    }
  );

  return myresponse.json();
};

export const AddPackageCategory = async (data) => {
  const myresponse = await fetch(
    "https://api.zupptravel.com/addHolidayCategory",
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );
  return myresponse.json();
};

export const AddPackageStyle = async (data) => {
  const myresponse = await fetch("https://api.zupptravel.com/addHolidayStyle", {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  return myresponse.json();
};
export const DeletePackage = async (newData) => {
  const myresponse = await fetch(
    `https://api.zupptravel.com/deleteHolidayCategory`,
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newData),
    }
  );
  return myresponse;
};
export const DeleteStyle = async (newData) => {
  const myresponse = await fetch(
    `https://api.zupptravel.com/deleteHolidayStyle`,
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newData),
    }
  );
  return myresponse;
};
export const GetHolidayCategory = async (newData) => {
  const myresponse = await fetch(
    "https://api.zupptravel.com/getHolidayCategory",
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newData),
    }
  );
  return myresponse.json();
};

export const GetHolidayStyle = async (newData) => {
  const myresponse = await fetch("https://api.zupptravel.com/getHolidayStyle", {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(newData),
  });
  return myresponse.json();
};

export const DeleteLocationManagement = async (id) => {
    const token = localStorage.getItem('token');
    const authHeader = `Bearer ${token}`
  const myresponse = await fetch(
    `https://api.zupptravel.com/api/v1/location/deleteLocation/${id}`,
    {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        'Authorization': authHeader
      },
    }
  );
  return myresponse.json();
};

export const DeletePincodeManagement = async (data) => {
  console.log("DeletePincodeManagement!!!!!", data);
  const myresponse = await fetch(
    "https://api.zupptravel.com/deletePincodeManagement",
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );
  return myresponse.json();
};

export const Addairline = async (newData) => {
  console.log(newData);
  const myresponse = await fetch("https://api.zupptravel.com/addAirline", {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(newData),
  });
  const myjson = await myresponse.json();
  console.log(myjson);
  return myjson;
};

export const DeleteAirline = async (data) => {
  console.log("!!!!!", data);
  const myresponse = await fetch("https://api.zupptravel.com/deleteAirline", {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  return myresponse.json();
};
