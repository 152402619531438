import React, { useState } from 'react';
// import { useHistory } from 'react-router-dom';
import {Col, Button, Row, Form} from 'react-bootstrap';
import { Formik } from 'formik';
import classes from '../WebsiteManagement/websiteManagement.module.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import DraftEditor from '../draftEditor';
import {useSelector} from "react-redux"
import {Createcoupon} from "../../entities/action/action"
import Image from '../../utilities/imageTestApi/image';
import moment from "moment";
const CreateCoupon = (props)=>{
    const data = useSelector(state=>state.adminAccount.apiResponse);
    const [message,updateMessage] = useState('');
    const [file,updateFile] = useState({});

    const categoryList = [
        {type:'Flight'},
        {type:'Hotels and Resorts'},
        {type:'Cabs'},
        {type:'Bus'},
        {type:'Homestays'},
        {type:'Hostels'},
        {type:'Camps'},
        {type:'Holidays'},
        {type:'Insurance'},
        {type:'Tour Guide'},
        {type:'Charter Plane'},
        {type:'Villas & Apartments'},
        {type:'Guest House'},
        {type:'Cottage'},
        {type:'Tree-House'},
        {type:'Motel'},
        {type:'Palace'},
        {type:'Houseboat'},
        {type:'All'}
    ]

    const [returnDate, setReturnDate] = useState();
    const [dateValue, setDateValue] = useState({});
    const [minReturnDate, setMinReturnDate] = useState("");
    const handleChange1 = (e) => {
        const { name, value } = e.target;
        setDateValue((prevValues) => ({
          ...prevValues,
          [name]: value,
        }));
      
        // Check if return date is less than or equal to departure date
        if (name === "startValidity") {
          // Set the minimum allowed return date based on the departure date
          setMinReturnDate(value);
        }
      };
    
    return(
        <div> 
        <Formik
            initialValues={{
                "active": true,
                "cashBack": '',
                "amount": 0,
                "termsAndCondition": '',
                "endValidityTime": '',
                "imageUrl": '',
                "loginRequired": '',
                "maxPrice": 0,
                "minPrice": 0,
                "playMoney":  0,
                "promocode": '',
                "promoname":  '',
                "usageLimit": 1,
                "discountType": '',
                "appliesTo": ''
            }}
            onSubmit={
                async(values,{setSubmitting,resetForm})=>{
                    console.log(values);
                    setSubmitting(true);
                        const response = await Image(file);
                        const newData = {
                            "active": true,
                            "cashBack":values.cashBack,
                            "amount":values.promoAmount,
                            "termsAndCondition": message,
                            "endValidity": dateValue?.endValidity,
                            "endValidityTime": values.endValidityTime,
                            "imageUrl": response,
                            "loginRequired": values.loginRequired,
                            "maxPrice": values.maxPrice,
                            "minPrice": values.minPrice,
                            "playMoney":  values.playMoney,
                            "promocode": values.promocode,
                            "promoname":  values.promoname,
                            "startValidity": dateValue?.startValidity,
                            "usageLimit": values.usageLimit,
                            "discountType":values.promotype,
                            "appliesTo":values.category
                        }
                        const res = await Createcoupon(newData);
                        if(res?.success){
                            alert("Successfully Added Coupon");
                            resetForm();
                            props.click();
                        }
                    setSubmitting(false);
                }
            }
            >
                {
                            ({values,errors,touched,handleChange,handleBlur,handleSubmit,isSubmitting})=>(
                                <Form onSubmit={handleSubmit}>
                                        <Form.Group controlId="formBasicPromo" as={Row}>
                                            <Form.Label column sm="2">PROMOCODE:</Form.Label>
                                            <Col sm="10"> 
                                                <Form.Control
                                                type="text" 
                                                placeholder="Enter PROMOCODE" 
                                                name="promocode"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.promocode}
                                                required/>
                                            </Col>
                                        </Form.Group>
                                        <hr/>
                                        <Form.Group controlId="formBasicPromo" as={Row}>
                                            <Form.Label column sm="2">PROMONAME:</Form.Label>
                                            <Col sm="10"> 
                                                <Form.Control
                                                type="text" 
                                                placeholder="Enter PROMONAME" 
                                                name="promoname"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.promoname}
                                                required/>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group controlId="formBasicAddress" as={Row}>
                                            <Form.Label column sm="2">VALIDITY:</Form.Label>
                                            <Col sm={4}> 
                                                <Form.Control 
                                                type="date" 
                                                placeholder="From" 
                                                min={moment().format("YYYY-MM-DD")}
                                                name="startValidity"
                                                onChange={handleChange1}
                                                onBlur={handleBlur}
                                                value={values.startValidity}
                                                required/>
                                            </Col>
                                            {/* <Form.Control
                                type="date"
                                placeholder="Departure Date"
                                min={moment().format("YYYY-MM-DD")}
                                name="departureDate"
                                onChange={handleChange1}
                                value={values.departureDate}
                                required
                              /> */}
                                            <Col sm={4}> 
                                                <Form.Control 
                                                type="date" 
                                                placeholder="To" 
                                                min={minReturnDate}
                                                name="endValidity"
                                                onChange={handleChange1}
                                                onBlur={handleBlur}
                                                value={values.endValidity}
                                                required/>
                                            </Col>
                                            {values.endValidity ? setReturnDate(true) : null}
                                            {/* <Form.Control
                                type="date"
                                placeholder="Return Date"
                                min={minReturnDate}
                                name="returnDate"
                                onChange={handleChange1}
                                value={values.returnDate}
                              /> */}
                              
                                            <Col sm={2}>
                                                <Form.Control 
                                                type="time" 
                                                placeholder="Time" 
                                                name="endValidityTime"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.endValidityTime}
                                                required/>
                                            </Col>
                                        </Form.Group>    
                                        <Form.Group controlId="formBasicLimit" as={Row}>
                                            <Form.Label column sm="2">USAGE LIMIT:</Form.Label>
                                            <Col sm="10"> 
                                                <Form.Control
                                                type="number" 
                                                placeholder="USAGE LIMIT" 
                                                name="usageLimit"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.usageLimit}
                                                required/>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group controlId="formBasicAppliesTo" as={Row}>
                                            <Form.Label column sm="2">APPLIES TO:</Form.Label>
                                            <Col sm="10"> 
                                                <Form.Control 
                                                as="select" 
                                                placeholder="CATEGORY" 
                                                name="category"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.category}
                                                required>
                                                    <option value="">--select--</option>
                                                    {
                                                        categoryList.map((categories,key)=>
                                                        <option key={key} value={categories.type}>{categories.type}</option>    
                                                        )
                                                    }
                                                </Form.Control>
                                            </Col>
                                        </Form.Group> 
                                        <Form.Group controlId="formBasicAddress" as={Row}>
                                            <Form.Label column sm="2">PRICE RANGE:</Form.Label>
                                            <Col sm={5}> 
                                                <Form.Control 
                                                type="number"
                                                min={0} 
                                                placeholder="Minimum" 
                                                name="minPrice"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.minPrice}
                                                required/>
                                            </Col>
                                            <Col sm={5}> 
                                                <Form.Control 
                                                type="number" 
                                                min={0}
                                                placeholder="Maximum" 
                                                name="maxPrice"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.maxPrice}
                                                required/>
                                            </Col>
                                        </Form.Group>
                                       
                                        <Row>
                                            <Col sm={2}>
                                                Images:
                                            </Col>
                                            <Col sm={10} style={{marginBottom:'1%'}}> 
                                            <Form.Control 
                                            type="file" 
                                            name="file"
                                            onChange={(e)=>updateFile(e.target.files[0])}
                                            required/>
                                            </Col>
                                        </Row>
                                        <fieldset>
                                            <Form.Group as={Row}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.loginRequired}
                                            required>
                                            <Form.Label as="legend" column sm={2}>
                                                Login Type:
                                            </Form.Label>
                                            <Col sm={5}>
                                                <Form.Check
                                                type="radio"
                                                label="NOT REQUIRED"
                                                name="loginRequired"
                                                value={false}
                                                />
                                            </Col> 
                                            <Col sm={5}>   
                                                <Form.Check
                                                type="radio"
                                                label="REQUIRED"
                                                name="loginRequired"
                                                value={true}
                                                />
                                            </Col>
                                            </Form.Group>
                                        </fieldset>
                                        <Form.Group controlId="formBasicPromo" as={Row} style={{marginTop:'2%'}}>
                                            <Form.Label column sm="2">Benifit Type:</Form.Label>
                                            <Col sm="10"> 
                                                <Row>      
                                                    <Col xs={2}>Cashback</Col>
                                                    <Col xs={10}>
                                                    <Form.Control
                                                        type="number" 
                                                        min={0}
                                                        placeholder="Cashback" 
                                                        name="cashBack"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.cashBack}
                                                        />
                                                    </Col>
                                                    <Col xs={2}>Play Money</Col>
                                                    <Col xs={10}>
                                                    <Form.Control
                                                        type="number" 
                                                        min={0}
                                                        placeholder="Play Money" 
                                                        name="playMoney"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.playMoney}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Form.Group style={{marginTop:"10px"}}  controlId="formBasicAppliesTo" as={Row}>
                                            <Form.Label column sm="2">Discount Type:</Form.Label>
                                            <Col sm="5"> 
                                                <Form.Control 
                                                as="select" 
                                                placeholder="Type" 
                                                name="promotype"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.promotype}
                                                >
                                                    <option value="">--select--</option>
                                                    <option value="fixed">Fixed</option>
                                                    <option value="percentage">Percentage</option>
                                                </Form.Control>
                                            </Col>
                                            <Col sm={5}> 
                                                <Form.Control 
                                                type="number" 
                                                placeholder="Amount" 
                                                name="promoAmount"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.promoAmount}
                                                />
                                            </Col>
                                        </Form.Group>
                                            </Col>
                                        </Form.Group>
                                        <Row style={{margin:'2% 0'}}>
                                            <Col sm={2} style={{margin:'2% 0'}} className={classes.Col}>
                                                Terms & Conditions:
                                            </Col>
                                            <Col sm={10} style={{border:'1px solid black'}}>
                                                <DraftEditor change={(text)=>updateMessage(text)} text={message} />
                                            </Col>
                                        </Row>
                                        <center>
                                            <Button type="submit" variant="success">Save Code</Button>
                                        </center>
                                    </Form>
                            )}
                        </Formik>
                    </div>            
    )
}
export default CreateCoupon;