import React from 'react';
import {Col,Button,Form, Row} from 'react-bootstrap';
import { Formik } from 'formik';
import classes from './WebsiteManagement/websiteManagement.module.css';
import { AddNewsletterUser } from '../entities/action/action';

const CreateNewsletterUser = (props)=>{

    return(
        <div className={classes.Box}> 
        <Formik
            initialValues={{email: "",phone: ""}}
            onSubmit={
                async(values,{setSubmitting,resetForm})=>{
                    setSubmitting(true);
                    const newData = {
                        "custEmailId":values?.email,
                        "phoneNumber": values?.phone,
                      }
                    

                    const res = await AddNewsletterUser(newData);
                    if(res?.success){
                        alert("Successfully Added Markup");
                        resetForm();
                        window.location.reload();
                    }
                    setSubmitting(false);
                }
            }
            >
                {
                            ({values,handleChange,handleBlur,handleSubmit})=>{
                                console.log(values);
                                return (
                                <Form onSubmit={handleSubmit}>
                                        <Form.Group controlId="formBasic" as={Row}>
                                            <Form.Label column sm="2">Email:</Form.Label>
                                            <Col sm="10"> 
                                                <Form.Control
                                                type="email" 
                                                placeholder="Enter Email" 
                                                name="email"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.email}
                                                required/>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group controlId="formBasicMetaTitle" as={Row}>
                                            <Form.Label column sm="2">Phone:</Form.Label>
                                            <Col sm="10"> 
                                                <Form.Control
                                                    type="text" 
                                                    placeholder="Enter Phone" 
                                                    name="phone"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.phone}
                                                    minLength={10}
                                                    maxLength={10}
                                                    required/>
                                            </Col>
                                        </Form.Group>
                                       
                                        <center>
                                            <Button type="submit" variant="success">Save Newsletter User</Button>
                                        </center>
                                    </Form>
                            )}}
                        </Formik>
                    </div>            
    )
}
export default CreateNewsletterUser;